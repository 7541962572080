import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';

import { ComparePlanVM } from '../../floorplan/compare-plans/compare-plan-vm';
import { HcSessionService } from '../../home-configurator/hc-session/hc-session.service';
import { BrochureFloorplanGroupVM } from '../../myscp/brochure/models/brochure-floorplan-group-vm';
import { BrochureGalleryVM } from '../../myscp/brochure/models/brochure-gallery-vm';
import { GalleryImageParentType, GalleryImageVM } from '../../shared/gallery/gallery-image-vm';
import { SpecsPluralPipe } from '../../shared/specsplural.pipe';
import { AmenityMapVM } from '../../site-map/models/amenity-map-vm';
import { LotVM } from '../../site-map/models/lot-vm';
import { StatusVM } from '../../site-map/models/status-vm';
import { AppModeTypes } from '../helpers/app-mode.enum';
import { Lookup } from '../helpers/lookup';
import { EffectiveFloorplan } from '../models/effective-floorplan';
import { FloorWithOptionsVM } from '../models/floor-with-options-vm';
import { FloorplanVM } from '../models/floorplan-vm';
import { InventoryHomeVM } from '../models/inventory-home-vm';
import { MediaVM } from '../models/media-vm';
import { ModuleGridItemVM } from '../models/module-grid-item-vm';
import { NeighborhoodVM } from '../models/neighborhood-vm';
import { CommunityService } from './community.service';
import { FavoritesService } from './favorites.service';
import { FloorplanRulesService } from './floorplan-rules.services';
import { FloorplanService } from './floorplan.service';
import { InventoryHomeService } from './inventory-home.service';
import { LotService } from './lot.service';
import { NavigationService } from './navigation.service';
import { NeighborhoodService } from './neighborhood.service';
import { SettingsService } from './settings.service';

import { IHomeBuyer, IHotspot, ISalesRep, LogicState, sortBySortOrder } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class ViewModelFactoryService {
  effectiveFpStore = new Map<string, EffectiveFloorplan>();

  constructor(
    private floorplanService: FloorplanService,
    private inventoryHomeService: InventoryHomeService,
    private favorites: FavoritesService,
    private hcSessionService: HcSessionService,
    private lookup: Lookup,
    private settingsService: SettingsService,
    private neighborhoodService: NeighborhoodService,
    private communityService: CommunityService,
    private floorplanRulesService: FloorplanRulesService,
    private specsPluralPipe: SpecsPluralPipe,
    private lotService: LotService,
    private navigator: NavigationService
  ) {}

  getEffectiveFloorplan(
    floorplanId: number,
    inventoryHomeId?: number,
    options?: {
      prefixWithNeighborhood?: boolean;
      getSpecsAsHtml?: boolean;
      useStore?: boolean;
      selectedOptionIds?: number[];
      filteredElevationIds?: number[];
    }
  ): EffectiveFloorplan {
    // Not using store by default as it caused regression due to favoriting and other stateful data being incorrectly cached
    const storeKey = `${floorplanId}_${inventoryHomeId || 0}`;
    if (options?.useStore && this.effectiveFpStore.has(storeKey))
      return this.effectiveFpStore.get(storeKey);

    const inventoryHome = this.inventoryHomeService.get(inventoryHomeId);
    if (!floorplanId && inventoryHome) floorplanId = inventoryHome.FloorPlanId;

    const fp = this.floorplanService.get(floorplanId);
    if (!fp) {
      console.error(`Unable to find floorplan Id ${floorplanId}`);
      return null;
    }

    const floorplan = new FloorplanVM(
      fp,
      this.favorites.isFavoritingEnabled() || this.navigator.AppMode === AppModeTypes.BrochureShare
        ? this.favorites.current
        : null,
      undefined,
      this.lookup.ApiProductDataEndpoint,
      options?.selectedOptionIds ??
        this.hcSessionService.currentBuild?.FloorPlan?.Options.map(x => +x.EntityId),
      options?.filteredElevationIds
    );
    floorplan.AssociatedLots = this.floorplanService.getAssociatedLots(
      floorplanId,
      fp.NeighborhoodId
    );

    let effectiveFP = new EffectiveFloorplan();

    if (inventoryHome) {
      const inventoryHomeVM = new InventoryHomeVM(
        inventoryHome,
        this.favorites.current,
        this.communityService.getBaseApiUrl(),
        floorplan,
        this.inventoryHomeService.getAssociatedLots(inventoryHomeId, floorplan.NeighborhoodId)
      );
      effectiveFP = effectiveFP.HydrateFromInventoryHome(
        inventoryHomeVM,
        floorplan,
        this.favorites.current
      );
    } else {
      effectiveFP = effectiveFP.HydrateFromFloorplan(floorplan);

      const favoritesReverseState = this.favorites.current.getReverseState(floorplanId);
      if (favoritesReverseState === null || favoritesReverseState === undefined) {
        effectiveFP.IsReversed = floorplan.IsReversedByDefault;
      } else {
        effectiveFP.IsReversed = favoritesReverseState;
      }
    }

    const neighborhood = this.neighborhoodService.get(floorplan.NeighborhoodId);
    const neighborhoodName = neighborhood.PublicName || neighborhood.Name;
    effectiveFP.NeighborhoodName = neighborhoodName;

    if (options?.prefixWithNeighborhood) {
      effectiveFP.Name = `${neighborhoodName} - ${floorplan.Name}`;
    }

    this.setupSpecCounts(effectiveFP, options?.getSpecsAsHtml);

    this.effectiveFpStore.set(storeKey, effectiveFP);
    return effectiveFP;
  }

  setupSpecCounts(effectiveFP: EffectiveFloorplan, getAsHtml?: boolean): void {
    if (!!effectiveFP.FloorCount) return;

    const onActionSets = effectiveFP.Floors.flatMap(x => x.ActionSets).filter(
      x => x.State === LogicState.ON
    );

    const getAdjustmentValue = (propName: string) =>
      onActionSets.map(x => x[propName]).reduce((sum, curr) => sum + curr, 0);

    const getEnabledOptionalFloorsCount = (): number => {
      const result = [...new Set(onActionSets.map(x => x.AffectedFloorId))].filter(Boolean);
      return result.length;
    };

    effectiveFP.FloorCount = this.getSpecCount(
      effectiveFP.FloorRange,
      effectiveFP.DefaultNumberOfFloors + getEnabledOptionalFloorsCount(),
      'SpecFloorLabel',
      getAsHtml
    );

    effectiveFP.BedCount = this.getSpecCount(
      effectiveFP.NumberOfBedroomsRange,
      effectiveFP.NumberOfBedrooms +
        (effectiveFP.IhOverrideNumberOfBedrooms ? 0 : getAdjustmentValue('BedroomAdj')),
      'SpecBedroomLabel',
      getAsHtml
    );

    effectiveFP.BathCount = this.getSpecCount(
      effectiveFP.NumberOfFullBathsRange,
      effectiveFP.NumberOfFullBaths +
        (effectiveFP.IhOverrideNumberOfFullBaths ? 0 : getAdjustmentValue('BathAdj')),
      'SpecFullBathLabel',
      getAsHtml
    );

    effectiveFP.GarageSizeCount = this.getSpecCount(
      effectiveFP.GarageCarSizeRange,
      effectiveFP.GarageCarSize +
        (effectiveFP.IhOverrideGarageCarSize ? 0 : getAdjustmentValue('GarageCarSizeAdj')),
      'SpecGarageTypeLabel',
      getAsHtml
    );
    effectiveFP.GarageCount = this.getSpecCount(
      effectiveFP.GarageNumberRange,
      effectiveFP.NumberOfGarages +
        (effectiveFP.IhOverrideNumberOfGarages ? 0 : getAdjustmentValue('GarageAdj')),
      'SpecGarageLabel',
      getAsHtml
    );

    effectiveFP.SqFtCount = this.getSpecCount(
      effectiveFP.SquareFeetRange,
      effectiveFP.BaseTotalSquareFeet + getAdjustmentValue('SquareFeetAdj'),
      'SpecSqFtLabel',
      getAsHtml
    );
    effectiveFP.HalfBathCount = this.getSpecCount(
      effectiveFP.NumberOfHalfBathsRange,
      effectiveFP.NumberOfHalfBaths +
        (effectiveFP.IhOverrideNumberOfHalfBaths ? 0 : getAdjustmentValue('HalfBathAdj')),
      'SpecHalfBathLabel',
      getAsHtml
    );
    effectiveFP.DenCount = this.getSpecCount(
      effectiveFP.DenRange,
      effectiveFP.NumberOfDens + getAdjustmentValue('DenAdj'),
      'SpecDenLabel',
      getAsHtml
    );

    effectiveFP.PriceDisplay = this.getPriceRange(effectiveFP);
  }

  private getPriceRange(fp: EffectiveFloorplan) {
    if (fp.PriceRange) return fp.PriceRange.startsWith('$') ? fp.PriceRange : `$${fp.PriceRange}`;
    else return formatCurrency(fp.Price, 'en-US', '$', 'USD', '1.0-2');
  }

  getSpecCount(
    range: string,
    baseValue: number,
    specLabelSettingName: string,
    getAsHtml?: boolean
  ): string {
    if (range) {
      if (getAsHtml) range = `<b>${range}</b>`;
      return `${range} ${this.specsPluralPipe.getPluralizedLabel(specLabelSettingName)}`;
    } else {
      return this.specsPluralPipe.transform(baseValue, specLabelSettingName, getAsHtml);
    }
  }

  getPlanForCompare(fp: EffectiveFloorplan): ComparePlanVM {
    const inMenuActionSets = this.floorplanRulesService.getInMenuActionSets(fp.FloorplanId);
    return new ComparePlanVM(
      fp,
      fp.Floors.map(
        f =>
          new FloorWithOptionsVM(
            f,
            inMenuActionSets,
            this.floorplanRulesService.isFloorDisabled(f.FloorId, fp.FloorplanId)
          )
      )
    );
  }

  getGalleryImageFromHotspot(hotspot: IHotspot, virtualDirectory?: string): GalleryImageVM {
    const vm = new GalleryImageVM(
      `${this.lookup.ApiProductDataEndpoint}${virtualDirectory}`
    ).HydrateFromMedia(new MediaVM(hotspot.Media), GalleryImageParentType.None, 0); // don't need parent type/id if not favoritable
    vm.IsFavoritable = false;
    return vm;
  }

  createHomeBuyerRegisterModule(homeBuyer?: IHomeBuyer): ModuleGridItemVM {
    return new ModuleGridItemVM({
      Title: homeBuyer ? 'Leave Session' : this.settingsService.get('MySCPRegistrationLabel'),
      Subtitle: homeBuyer ? `${homeBuyer.FirstName} ${homeBuyer.LastName}` : undefined,
      IconName: homeBuyer ? 'registration_logout' : 'registration_login',
      ShowResetButton: homeBuyer !== null,
      ResetButtonTooltip: 'Clear Session Data',
      UniqueName: ModuleGridItemVM.HomeBuyerRegisterUniqueName
    });
  }

  createSalesPinModule(rep: ISalesRep): ModuleGridItemVM {
    const salesConsultantTitle = this.settingsService.get('SalesConsultantTitle') ?? '';

    return new ModuleGridItemVM({
      Title: `${salesConsultantTitle}`,
      Subtitle: rep ? `${rep.FirstName} ${rep.LastName}` : undefined,
      IconName: rep ? 'logout' : 'login',
      UniqueName: ModuleGridItemVM.SalesPinUniqueName
    });
  }

  getNeighborhood(id: number): NeighborhoodVM {
    return new NeighborhoodVM(
      this.neighborhoodService.get(id),
      this.communityService.getBaseApiUrl()
    );
  }

  getLot(id: number) {
    return new LotVM(this.lotService.get(id));
  }

  /** Special case: community map media hotspots don't have Media, just Url */
  getGalleryImageFromCommunityMapHotspot(hotspot: IHotspot): GalleryImageVM {
    const vm = new GalleryImageVM('', '', hotspot.Title);
    vm.Id = hotspot.HotspotId;
    if (hotspot.Url?.startsWith('http')) {
      vm.FullUrl = hotspot.Url;
      vm.IsIframe = true;
    } else {
      vm.FullUrl = this.getProperUrl(hotspot.Url);
    }
    vm.IsFavoritable = false;
    vm.ParentType = GalleryImageParentType.CommunityMap;
    return vm;
  }

  private getProperUrl(url: string) {
    if (url.startsWith('http')) return url;
    return this.lookup.ApiProductDataEndpoint + url.replace('/productdata', '');
  }

  getAllAmenityMaps(): AmenityMapVM[] {
    return this.communityService
      .getAmenityMaps()
      .map(
        amenityMap =>
          new AmenityMapVM(
            amenityMap,
            this.communityService.getBaseApiUrl(),
            this.favorites.current
          )
      );
  }

  getAmenityMap(id: number): AmenityMapVM {
    return new AmenityMapVM(
      this.communityService.getAmenityMaps().find(x => x.AmenityMapId === id),
      this.communityService.getBaseApiUrl(),
      this.favorites.current
    );
  }

  getBrochureFloorplanGroup(
    floorplan: EffectiveFloorplan,
    elevations: BrochureGalleryVM,
    media: BrochureGalleryVM
  ): BrochureFloorplanGroupVM {
    const neighborhood = this.neighborhoodService.get(floorplan.NeighborhoodId);
    const neighborhoodName = neighborhood.PublicName || neighborhood.Name;
    return {
      Floorplan: floorplan,
      ElevationGallery: elevations,
      MediaGallery: media,
      // always have hood before but not twice if setting is on
      Title: this.settingsService.get('ShowNeighborhoodNameBeforeFloorplanName')
        ? floorplan.Name
        : `${neighborhoodName} - ${floorplan.Name}`,
      FavesCount: 1 + (elevations?.Images.length ?? 0) + (media?.Images.length ?? 0)
    };
  }

  getLotStatusesForNeighborhood(neighborhoodId): StatusVM[] {
    const neighborhood = this.neighborhoodService.get(neighborhoodId);
    let statuses = neighborhood.LotMapStatuses.map(x => new StatusVM(x)).sort(sortBySortOrder);
    neighborhood.Lots.forEach(l => {
      const status = statuses.find(x => x.LotMapStatusId === l.StatusId);
      if (status) status.LotCount++;
    });

    const lotsToAlwaysDisplay = this.settingsService.get('LotStatusesToAlwaysDisplay');

    if (this.settingsService.get('HideUnusedLotStatuses'))
      statuses = statuses.filter(s => s.LotCount || lotsToAlwaysDisplay.includes(s.Name));

    return statuses;
  }
}
